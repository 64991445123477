<template>
	<div>
		<div style="display: flex; justify-content: flex-start; gap: 15px;">
			<div class="market-summary">

				<div style="display: flex; justify-content: flex-start; gap: 15px; margin: 10px 0 20px;">
					<div>
						<select class="select-styled" v-model="chosenMarket">
							<option v-for="opt in availMarkets" :key="opt.id" :value="opt.id">{{opt.name}}</option>
						</select>
					</div>
					<div>
						<select class="select-styled" v-model="chosenDemographic">
							<option v-for="opt in availDemographics" :key="opt.id" :value="opt.id">{{opt.name}}</option>
						</select>
					</div>
					<div>
						<select class="select-styled" v-model="chosenDaypart">
							<option v-for="opt in availDayparts" :key="opt.id" :value="opt.id">{{opt.name}}</option>
						</select>
					</div>
					<div>
						<select class="select-styled" v-model="chosenStatistic">
							<option v-for="opt in availStatistics" :key="opt.id" :value="opt.id">{{opt.name}}</option>
						</select>
					</div>
					<div style="margin-top: 10px; display: flex; align-items: center; justify-content: center">
						<input id="chtrln" type="checkbox" v-model="useLineChart" />
						<label for="chtrln" style="font-size: 12px; margin: 1px 0 0 5px; display: inline-block">Trend Line chart</label>
					</div>
				</div>

				<ChartHolderDash :charttitle="chartTitle" :source="chartSource" :height="550">
					<template v-slot:default>
						<p style="padding: 20px 20px;" v-if="chartdata.length === 0">There's either no Xtra CRA Gold Standard survey data for this market, or the demographic/daypart selection has no data (perhaps due to sample size)</p>
						<ApexFreqLineTrendMulti v-if="useLineChart && chartdata.length > 0" :chartdata="chartdata" :chartcolors="chartcolors" :fetching="fetchingCharts"></ApexFreqLineTrendMulti>
						<ApexFreqBar v-if="useLineChart === false  && chartdata.length > 0" :chartdata="chartdata" :chartcolors="chartcolors" :fetching="fetchingCharts"></ApexFreqBar>
					</template>
				</ChartHolderDash>

				<div>
					<span @click="showMarketSummary" class="link-text" style="font-size: 12px; margin-left: 0px;">Market Summary Popup</span>
				</div>

				<div v-if="showSummaryView" class="loader-overlay">
					<div class="market-summary-view">
						<MarketSummary :aspopup="true" :mktob="summaryMarketOb" v-on:close="showSummaryView = false"></MarketSummary>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
// import axios from "axios";
import ChartHolderDash from "@/components/ChartHolderDash";
import ApexFreqBar from "@/components/arnmarkets/ApexFreqBar";
import ApexFreqLineTrendMulti from "@/components/arnmarkets/ApexFreqLineTrendMulti";
import MarketSummary from "@/components/arnmarkets/MarketSummary";

export default {
	name: "FreqChartExplorer",
	components: {
		MarketSummary,
		ApexFreqBar,
		ApexFreqLineTrendMulti,
		ChartHolderDash,
	},
	mixins: [globalMixin],
	props: {},
	data: function () {
		return {
			store: store,
			fetchingCharts: false,
			showSummaryView: false,
			summaryMarketOb: null,
			defaultColorCount: 0,
			collatedMarketData: [],
			useLineChart: false,

			chartdata: [],
			chartcolors: [],

			allowedDemoIds: [48,50,532,118,1323,152,2316,1322,266,239,3131,159,272,255,535,803,117,373,151,49,1321,265,238,3130,158,271,64,65,374,78,1326,263,66,458,3129,68,181],
			allowedDayparts: [1,3,4,5,6,7,8,9],

			chosenMarket: 0,
			chosenDemographic: 48,
			chosenStatistic: 'cume',
			chosenDaypart: 1,

			surveynames: [],
		}
	},
	computed: {
		availMarkets() {
			return this.store.markets.filter(item => item.isGfk === false && item.surveyed === true && item.ownership.owns.includes('arn'));
		},
		availDemographics() {
			return this.store.demos.filter(item => this.allowedDemoIds.includes(item.id));
		},
		availStatistics() {
			let myallowed = ['cume','stationListenedMost']
			if(this.store.gfkMktIds.includes(this.chosenMarket)) {
				myallowed = ['cume','shareToAll']
			}
			return this.store.statistics.filter(item => myallowed.includes(item.id));
		},
		availDayparts() {
			return this.store.dayparts.filter(item => this.allowedDayparts.includes(item.id))
		},
		appliedStations() {
			let stns = this.store.stations.filter(item => item.mktid === this.chosenMarket && item.band !== 'dab' && item.outsidemkt !== true && item.community === false && ((item.isCommercial === true && item.band.length > 0) || item.subnetown === 'abc_loc' || item.subnetown === 'abc_j'));
			let stationids = [];
			for(let s=0; s<stns.length; s++) {
				stationids.push(stns[s].id);
			}
			return stationids;
		},
		chartTitle() {
			let dem = this.store.demos.find(item => item.id === this.chosenDemographic);
			let mkt = this.store.markets.find(item => item.id === this.chosenMarket);
			let stt = this.store.statistics.find(item => item.id === this.chosenStatistic);
			let dp = this.store.dayparts.find(item => item.id === this.chosenDaypart);
			if(dem && mkt && stt) {
				return mkt.name + ", " + dem.name + ", " + stt.name+ ", " + dp.name;
			}
			return '';
		},
		chartSource() {
			let dem = this.store.demos.find(item => item.id === this.chosenDemographic);
			let mkt = this.store.markets.find(item => item.id === this.chosenMarket);
			let stt = this.store.statistics.find(item => item.id === this.chosenStatistic);
			let dp = this.store.dayparts.find(item => item.id === this.chosenDaypart);
			if(dem && mkt && stt) {
				return "Xtra Insights " + mkt.name + " surveys (" + this.surveynames.join(", ") + "), " + dem.name + ", " + stt.name + ", " +dp.name;
			}
			return '';
		},
		cdatalength() {
			return this.chartdata.length;
		},
		activeMarketOb() {
			return this.store.markets.find(item => item.id === this.chosenMarket)
		},
		surveyList() {
			if(this.activeMarketOb) {
				return this.store.surveys.filter(item => item.mktid === this.activeMarketOb.id)
			}
			return []
		},
		surveyListIds() {
			let ss = []
			for(let su of this.surveyList) {
				ss.push(su.id)
			}
			return ss
		},
	},
	methods: {
		getChartData() { //for cache lookup
			if(this.fetchingCharts === false) {
				this.fetchingCharts = true
				let self = this
				let sets = []
				let type = 'standard'
				if(this.chosenMarket === 900) type = 'smbap'
				sets.push({
					type: type,
					dayparts: [this.chosenDaypart],
					demos: [this.chosenDemographic],
					statistics: [this.chosenStatistic],
					surveylist: this.surveyListIds,
					stationIds: this.appliedStations,
					market: this.chosenMarket,
					audioTypeIds: [0],
				})
				this.doGetCacheDataAudology(sets).then(function(ret) {
					if(ret.error === 1) console.log(ret)
					else {
						self.collatedMarketData = ret.returnedData
						self.prepareChartData()
						self.fetchingCharts = false
					}
				})
			}
		},
		prepareChartData() {
			this.chartdata = []
			let cdata = [];
			let colors = [];
			this.defaultColorCount = 0
			this.surveynames = []
			if (this.chosenDemographic > 0 && this.chosenStatistic.length > 0 && this.chosenDaypart > 0) {
				this.store.defaultColorCount = 0
				for (let s = 0; s < this.appliedStations.length; s++) {
					let stid = this.appliedStations[s]
					let stnob = this.store.stations.find(item => item.id === stid);
					if(stnob) {
						let myrow = []
						myrow = {
							name: stnob.name,
							data: [],
						}
						colors.push(this.getChartColorForStation(stnob, colors));
						for(let surv of this.surveyList) {
							myrow.data.push({x: surv.nicename, y: this.getCellDataAud(stnob.id, surv.id, this.collatedMarketData, this.chosenStatistic, this.chosenDaypart, this.chosenDemographic, 0, false)})
						}
						myrow.data.reverse()
						cdata.push(myrow)
					}
				}
				this.chartdata = cdata
				this.chartcolors = colors
			}
		},
		showMarketSummary() {
			this.summaryMarketOb = this.store.markets.find(item => item.id === this.chosenMarket);
			if(this.summaryMarketOb) {
				this.showSummaryView = true;
			}
		},
		prepareFirstLoad() {
			this.chosenMarket = this.availMarkets[0].id
		},
	},
	watch: {
		chosenMarket() { this.getChartData(); },
		chosenStatistic() { this.getChartData(); },
		chosenDemographic() { this.getChartData(); },
		chosenDaypart() { this.getChartData(); },
		'store.initialObjectsReceived': {
			handler() {
				if(this.store.initialObjectsReceived === true) this.prepareFirstLoad()
			},
		},
	},
	mounted() {
		if(this.store.initialObjectsReceived === true) this.prepareFirstLoad()
		document.title = 'Xtra Regional Ratings Charts';
	}
}
</script>

<style scoped>
.market-summary {
	display: block;
	overflow-y: auto;
	height: 100%;
	width: 100%;
}
.content-block p {
	line-height: 1.6;
}
.market-summary-view {
	width: 90%;
	height: 90%;
	position: fixed;
	top: 5%;
	left: 5%;
}
@media screen and (max-width: 1350px) {

}
</style>